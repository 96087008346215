import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font40">Where Every Edge Connects, Innovation Begins.</h1>
          <HeaderP className="font13 semiBold">
          At ConnectEdge Enterprises, our diverse array of services is tailored to meet the evolving needs of businesses in a rapidly advancing landscape. From cutting-edge hardware solutions encompassing laptops, desktops, data servers, indoor video walls, ticketing machines, and VPS, to comprehensive software services spanning UI/UX Figma designs, web portfolios, websites, mobile applications, and robust backend microservices — we are your one-stop solution for all your technology needs.

Our expertise extends beyond the digital realm. We excel in SEO and digital marketing strategies, ensuring your brand gets the visibility it deserves. From network infrastructure and cabling to the meticulous installation of CCTV cameras, our commitment to seamless connectivity is unwavering.

In addition to our technological prowess, ConnectEdge Enterprises offers prime transportation services, coupled with vehicle repair and maintenance services to keep your operations running smoothly. Our capabilities extend to the physical realm, covering renovations, constructions, and the efficient supply and management of electrical resources.At ConnectEdge Enterprises, we passionately embrace the challenge of transforming visions into reality. With a relentless commitment to innovation and excellence, we navigate the dynamic landscape of technology, construction, and connectivity, crafting solutions that not only meet but exceed expectations. Your success is our inspiration, and together, we build a future where possibilities have no limits!
          </HeaderP>
          {/* <BtnWrapper>
            <FullButton title="Get Started" />
          </BtnWrapper> */}
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Thank you for choosing ConnectEdge Enterprises. Let's build, connect, and innovate for a future without limits.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Jeff Smith - CEO</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


