import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
// import FullButton from "../Buttons/FullButton";
// Assets
// import AddImage1 from "../../assets/img/add/1.png";
// import AddImage2 from "../../assets/img/add/2.png";
// import AddImage3 from "../../assets/img/add/3.png";
// import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13">
            Our comprehensive suite of services is designed to cater to your unique needs, providing a seamless and transformative experience. From cutting-edge solutions to personalized support, we are committed to exceeding expectations. Discover a new standard of excellence with our services—your pathway to success starts here.
              {/* <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="hardwareSolution"
                title="Hardware Solutions"
                subtitle="We offer a comprehensive range of hardware solutions, spanning from laptops and desktops to robust data servers and racks. Our expertise extends to indoor video walls, EQMS/ticketing machines, POS systems, and VPS configurations."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="softwareSolution"
                title="Software Development"
                subtitle="Unlock the power of innovative software with our services. From creative UI/UX Figma designs to dynamic web portfolios, websites, and cutting-edge mobile applications, we provide end-to-end solutions. Our proficiency also includes backend services and microservices development."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="marketingSolution"
                title="Marketing Excellence"
                subtitle="Elevate your online presence with our SEO and digital marketing strategies. We specialize in crafting campaigns that enhance visibility and drive meaningful engagement."
              />
            </ServiceBoxWrapper>
            
            <ServiceBoxWrapper>
              <ServiceBox icon="networkSolution" title="Network Solutions" subtitle="Ensure seamless connectivity with our network infrastructure and cabling services. Our expertise includes the installation and configuration of CCTV cameras, as well as hardware and software systems." />
            </ServiceBoxWrapper>

          </ServiceBoxRow>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="transportationSolution"
                title="Transportation Services"
                subtitle="Experience prime transportation services with a touch of executive luxury. We provide reliable and efficient transport solutions tailored to your needs."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="vehicleSolution"
                title="Vehical Services"
                subtitle="Keep your vehicles in top-notch condition with our mechanical and electrical repairing and maintenance services. We handle vehicle registrations and facilitate the sale/purchase of vehicles. Additionally, explore our range of automotive engine lubricants for optimal performance."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="renovationSolution"
                title="Renovation and Construction"
                subtitle="Transform your office or bank space with our expert renovation and construction services. We bring innovation and precision to every project."
              />
            </ServiceBoxWrapper>
            
            <ServiceBoxWrapper>
              <ServiceBox icon="electricSolution" title="Electrical Solutions" subtitle="From electrical supplies to wiring and overall electrical management, our services are designed to meet your electrical needs efficiently." />
            </ServiceBoxWrapper>

          </ServiceBoxRow>
          
        </div>
        {/* <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">A Study of Creativity</h2>
                <p className="font12">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                  diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={() => alert("clicked")} />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={() => alert("clicked")} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
// const Advertising = styled.div`
//   margin: 80px 0;
//   padding: 100px 0;
//   position: relative;
//   @media (max-width: 1160px) {
//     padding: 100px 0 40px 0;
//   }
//   @media (max-width: 860px) {
//     flex-direction: column;
//     padding: 0 0 30px 0;
//     margin: 80px 0 0px 0;
//   }
// `;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
// const AddLeft = styled.div`
//   width: 50%;
//   p {
//     max-width: 475px;
//   }
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//     text-align: center;
//     h2 {
//       line-height: 3rem;
//       margin: 15px 0;
//     }
//     p {
//       margin: 0 auto;
//     }
//   }
// `;
// const AddRight = styled.div`
//   width: 50%;
//   position: absolute;
//   top: -70px;
//   right: 0;
//   @media (max-width: 860px) {
//     width: 80%;
//     position: relative;
//     order: 1;
//     top: -40px;
//   }
// `;
// const AddRightInner = styled.div`
//   width: 100%;
// `;
// const AddImgWrapp1 = styled.div`
//   width: 48%;
//   margin: 0 6% 10px 6%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;
// const AddImgWrapp2 = styled.div`
//   width: 30%;
//   margin: 0 5% 10px 5%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;
// const AddImgWrapp3 = styled.div`
//   width: 20%;
//   margin-left: 40%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;
// const AddImgWrapp4 = styled.div`
//   width: 30%;
//   margin: 0 5%auto;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;